.site-header{
	position: fixed;
	width: 100%;
	z-index: 2;
	transition: background-color .3s ease-out;

	&--is-expanded {
		background-color: orange;
		padding-bottom: 18px;
	}
	
	@include atMedium(){
		position: absolute;
		position: fixed;
		background-color: rgba(#000, .4);

		&--dark {
			background-color: rgba(#000, .7);
		}
	}

	&__btn-container{
		@include atMedium(){
			padding: 32px 0px 32px 0px;
			float: right;
			transition: padding .3s ease-out;

			&--small{
				padding: 13px 0px 13px 0px;
			}
		}
	}

	&__language{
		position: absolute;
		top: 10px;
		left: -5px;
		border-radius: 15px;
		overflow: hidden;
		width: 25px;
		height: 25px;

		@include atMedium(){
			position: unset;
			margin: 40px 20px 0px 0px;
			float: right;
			transition: margin .3s ease-out;

			&--small{
				margin: 22px 20px 0px 0px;
			}
		}
	}

	&__menu-icon{
		width: 20px;
		height: 19px;
		position: absolute;
		z-index: 10;
		top: 10px;
		right: 10px;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 3px;
			background: #FFF;
			transform-origin: 0 0;
			transition: transform .3s ease-out;
		}

		&__middle {
			position: absolute;
			top: 8px;
			left: 0;
			width: 20px;
			height: 3px;
			background: #FFF;
			transition: all .3s ease-out;
			transform-origin: 0 50%;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 20px;
			height: 3px;
			background: #FFF;
			transform-origin: 0 100%;
			transition: transform .3s ease-out;
		}

		@include atMedium {
			display: none;
		}
	}

	&__menu-icon--close-x {
		&::before {
			transform: rotate(45deg) scaleX(1.25);
		}

		.site-header__menu-icon__middle{
			transform: scaleX(0);
			opacity: 0;
		}

		&::after {
			transform: rotate(-45deg) scaleX(1.25) translateY(1px);

		}
	}

	&__menu-content{
		opacity: 0;
		transform: scale(1.2);
		transtion: all .3s ease-out;
		position: relative;
		z-index: -10;
		padding-top: 90px;
		text-align: center;
		display: none;

		&--is-visible{
			display: block;
			transform: scale(1);
			opacity: 1;
			z-index: 1;
		}

		@include atMedium(){
			display: inline;
			opacity: 1;
			z-index: 1;
			padding-top: 0;
			transform: scale(1); 
		}
	}

	&__logo{
		position: absolute;
		top: -1px;
		padding: 5px 15px;
		left: 50%;
		transform-origin: 50% 0%;
		transform: translateX(-50%) scale(.8);
		transition: all .3s ease-out;
		background-color: rgba(black, 0.5);

		&--small {
			transform: translateX(-50%) scale(.5);
			background-color: rgba(black, 0.7);
		}

		&--orange-bg {
			transition: all .3s ease-out;
			background-color: orange;
			transform: translateX(-50%) scale(.8);
		}

		@include atMedium(){
			width: 170px;
			padding: 0;
			top: 3px;
			position: relative;
			float: left;
			left: auto;
			transform: translateX(0);
			background-color: rgba(black, 0);
			transition: width .3s ease-out;

			&--small {
				width: 100px;
			}
		}
	}

}