.wrapper{
	overflow: hidden;
	padding-left: 18px;
	padding-right: 18px;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;

	&--medium{
		max-width: 976px;
	}

	&--centered{
		text-align: center;

		@include atLarge{
			text-align: left;
		}
	}

	&--small{
		max-width: 750px;
	}

}