.primary-nav{
	padding-top: 10px;
	
	@include atMedium(){
		padding: 22px 0px;
		transition: padding .3s ease-out;

		&--small{
			padding: 2px 0px;
		}
	}

	&--pull-right{
		@include atMedium(){
			float: right;
		}
	}

	ul {
		margin: 0;
		padding: 0;

		@include clearfix();
	}

	li{
		list-style: none;
		display: inline-block;
		padding-right: 7px;
		padding-top: 10px;

		@include atMedium(){
			float: left;
			display: block;
			padding-right: 20px;

		}
	}

	li:last-child {
		padding-right: 0;

		@include atMedium(){
			padding-right: 20px;
		}
	}

	a{
		color:  #fff;
		font-weight: 400;
		text-decoration: none;
		display: block;
		padding: 5px 8px;
		font-size: .8rem;
		background:rgba(black, .5);
		border-radius: 30px;

		@include atMedium(){
			background:transparent;
			font-size: 1.10rem;
			padding: 12px 0;

			&.is-current-link {
				color: #fabb69;
			}
		}
	}
}