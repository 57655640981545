.page-section {
  position: relative;

  &--t-margin-external {
    margin-top: -70px;
  }

  &--b-margin-external {
    @include atMedium() {
      margin-bottom: -140px;
    }
  }

  &--tb-padding {
    padding: 1.2rem 0;

    @include atMedium() {
      position: unset;
      padding: 4.5rem 0;
    }
  }

  &--t-padding {
    padding-top: 5.2rem;

    @include atMedium() {
      position: unset;
      padding-top: 4.5rem;
    }

    &-larger {
      padding-top: 5.2rem;

      @include atMedium() {
        position: unset;
        padding-top: 6rem;
      }
    }
  }

  &--b-padding-small {
    padding-bottom: 1rem;

    @include atMedium() {
      position: unset;
      padding-top: 3.8rem;
    }
  }

  &--b-margin {
    margin-bottom: 70px;

    @include atMedium {
      margin-bottom: -155px;
    }
  }

  &--red {
    background-color: $myRed;
    color: #fff;
  }

  &--darkGreen {
    background-color: $myDarkGreen;
    color: #fff;
  }

  &--lightGreen {
    background-color: $myLightGreen;
    color: #fff;
  }

  // &--testimonials{
  // 	background: #e0e6ef;

  // 	// @include atMedium(){

  // 	// 	&.lazyloaded {
  // 	// 		background: url('../../assets/images/testimonials-bg.jpg') top center no-repeat;
  // 	// 		background-size: cover;
  // 	// 	}
  // 	// }
  // }
}
