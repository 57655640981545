.large-hero {
	border-bottom: 10px solid orange;
	text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
	position: relative;

	&__image{
		display: block;
	}

	&__text-content {
		// padding-top: 88px;
		position: absolute;
		top: 50%;
		left: 0px;
		width: 100%;
		transform: translateY(-50%);
		text-align: center;

		@include atMedium(){
			padding-top: 60px;
		}
	}

	&__title {
		font-family: "CocogooseBold";
		color: white;
		font-size: 2.2rem;
		margin: 0;

		@include atSmall(){
			font-size: 3.2rem;
		} 

		@include atMedium(){
			font-size: 4.8rem;
		} 

	}

	&__subtitle {
		// font-family: "CocogooseNormal";
		font-weight: 400;
		color: white;
		font-size: 1.3rem;
		margin: 0;

		@include atSmall(){
			font-size: 1.8rem;
		}

		@include atMedium(){
			font-size: 2.4rem;
		} 
	}

	&__description {
		color: #FFF;
		font-weight: 00;
		font-size: 1.1rem; 
		max-width: 50rem;
		margin-left: auto;
		margin-right: auto;

		@include atSmall(){
			font-size: 1.3rem;
		}

		@include atMedium(){
			font-size: 1.875rem;
		} 
	}


}