@font-face {
  font-family: "CocogooseNormal";
  src: url("../media/fonts/cocogoose/Cocogoose_medium.ttf") format("truetype");
}

@font-face {
  font-family: "CocogooseLight";
  src: url("../media/fonts/cocogoose/Cocogoose_light.ttf") format("truetype");
}

@font-face {
  font-family: "CocogooseBold";
  src: url("../media/fonts/cocogoose/Cocogoose_bold.ttf") format("truetype");
}

@font-face {
  font-family: "CocogooseExtra";
  src: url("../media/fonts/cocogoose/Cocogoose_extraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "BS-regular";
  src: url("../media/fonts/bahnschrift/BS_Static_Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "BS-light";
  src: url("../media/fonts/bahnschrift/BS_Static_Light.ttf") format("truetype");
}

@font-face {
  font-family: "BS-bold";
  src: url("../media/fonts/bahnschrift/BS_Static_Bold.ttf") format("truetype");
}
