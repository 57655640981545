.generic-content-container{

	&--t-b-margin{
    	margin-top: 4rem;
    	margin-bottom: 2.5rem;

    	@include atLarge{
    		margin-top: 5rem;
    		margin-bottom: 0px;
    	}
	}

	&--t-center{
		text-align: center;
	}

	p {
		font-weight: 300;
		line-height: 1.25;
		margin: 0;
		font-size: 1.1rem;

		@include atMedium{
			margin: 0 0 1.8rem 0;
			font-size: 1.4rem;
		}
	}

	p a {
		font-weight: 500;
	}

}