.video-container {
  position: relative;
  padding-bottom: 63.15%;
  height: 0;
  overflow: hidden;
  box-shadow: -3px 3px 10px #000000ba;
  margin-right: -18px;
  margin-left: -18px;

  @include atSmall {
    padding-bottom: 60%;
  }

  @include atMedium {
    padding-bottom: 59%;
  }

  @include atLarge {
    padding-bottom: 55.7%;
    margin-right: 0px;
    margin-left: 0px;
  }
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
