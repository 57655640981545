.headline{
	margin:auto;

	strong {
		font-weight: 800;
	}

	&--lt-margin{
		margin-top: -5px;

		@include atLarge{
			margin-left: 55px;
		}
	}

	&--s-width {
		max-width: 500px;

		@include atMedium(){
			max-width: 1000px;
		}

	}

	&__title {
		font-weight: 400;
		font-size: 1.5rem;

		@include atMedium(){
			font-size: 2.1rem;
		}
	}

	&__subtitle {
		font-weight: 300;
		font-size: 1.3rem;

		@include atMedium(){
			font-size: 1.6rem;
		}
	}

	&__img{
		margin: 30px 0px;
	}
	
	&--centered {
		text-align: center;
	}

	&--lightGreen {
		color: $myLightGreen;
	}

	&--orange {
		color: $myOrange;
	}

	&--b-margin {
		margin-bottom: 2.5rem;

		@include atMedium(){
			margin-bottom: 4.5rem;
		}
	}

	a {
		font-weight: 400;
	}
}