.how-it-works{
	margin-bottom: 2.5rem;

	strong {
		font-weight: 800;
	}

	&--atSmall-centered{
		text-align: center;

		@include atLarge{
			text-align: left;
		}
	}

	&--atSmall-t-margin{
		margin-top: 6rem !important;

		@include atMedium{
			margin-top: 4rem !important;
		}

		@include atLarge{
			margin-top: 100px;
		}
	}

	&--centered{
		position: absolute;
		top: 0px;
		width: 100%;
		text-align: center;
	}

	&--t-margin{

		@include atSmall{
			margin-top: 10px;
		}

		@include atMedium{
			margin-top: -45px;
		}
	}

	&__last-section{
		padding: 3rem 0px 50px 0px;
		background-color: rgba(white,0.9);

		@include atLarge{
			padding: 100px 0px 50px 0px;
		}
	}

	p{
		font-weight: 300;
		line-height: 1.125;
		margin: 0;
		font-size: 1.2rem;

		@include atMedium{
			margin: 0 0 1.8rem 0;
			font-size: 1.4rem;
		}
	}

	&__title{
		font-weight: 300;
		font-size: 1.4rem;
		margin-bottom: 45px;

		@include atMedium(){
			margin-top: 10px;
			font-size: 2.1rem;
		}
		
	}

	&__title-small{
		font-weight: 300;
		font-size: 1.4rem;
		margin-bottom: 45px;

		@include atMedium(){
			margin-top: 10px;
			font-size: 2.1rem;
		}
		
	}

	&__img{
		// display: none;

		// @include atLarge{
		// 	display: inline-block;
		// }

		&--small-only{
			display: inline-block;
			margin: 10px 0px;

			@include atLarge{
				display: none;
			}
		}
	}

	&__icon--1{
		width: 150px;

		@include atMedium{
			width: 200px;
		}

		@include atLarge{
			width: 225px;
			margin-left: 80px;
			margin-top: 100px;
		}
	}

	&__icon--membership{
		margin-right: .8rem;
		margin-bottom: -35px;
	}

	&__icon--workgroup-order{
		margin-right: .8rem;
		margin-bottom: -30px;
		margin-top: 1.2rem;

		@include atMedium{
			margin-top: 3.2rem;
		}

		@include atLarge{
			margin-top: 0rem;
		}
	}

	&__icon--pickup{
		margin-bottom: -30px;
		margin-right: 20px;
	}
}
