.btn{
	background-color: #00ab00;;
	color: #fff;
	text-decoration: none;
	padding: .75rem 1.2rem;
	display: inline-block;
	border-radius: 30px;

	&--l-margin{
		margin-left: 20px;
	}

	&--medium {
		margin-bottom:1.5rem;
		margin-top: 1.5rem;

		@include atSmall(){
			font-size: 1.1rem;
		}
	}

	&--large {
		padding: 1.1rem 1.9rem;

		@include atSmall(){
			font-size: 1.25rem;
		}
	}

	&--pickupday {
		margin: 30px 0px;

		@include atMedium(){
			margin: 0px;
		}
	}
} 