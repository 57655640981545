* {
	box-sizing: border-box;
}

body {
	position: relative;
	font-family: 'roboto', sans-serif;
	color: #333;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	font-weight: 300;
	text-decoration: none;
}

