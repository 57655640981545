.modal {
	position: fixed;
	z-index: 5;
	background:rgba(255, 255, 255, .94);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.2);
	transition: all .3s ease-out;

	&--is-visible {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}

	&__inner {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;

	}

	&__title {
		font-weight: 500;
		font-size: 2.2rem;
	}

	&__description {
		text-align: center;
		font-size: 1.3rem;
		font-weight: 300;
		line-height: 1.65;
	}

	&__close {
		position: absolute;
		top: 15px;
		right: 15px;
		font-size: 2rem;
		transform: scaleX(1.2);
		transform-origin: 100% 0;
		color: $myOrange;

		&:hover {
			color: $myOrange;
			cursor: pointer;
		}
	}
}