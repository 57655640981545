@mixin atSmall {
	@media (min-width: 530px) { @content };
}

@mixin atMedium {
	@media (min-width: 800px) { @content };
}

@mixin atLarge {
	@media (min-width: 1200px) { @content };
}

@mixin clearfix {
	&::after{
		content: "";
		clear: both;
		display: table;
	}
}